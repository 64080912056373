module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"D:\\BuildsVsTs\\DigiLab_006_004_COLAS\\_work\\5\\s"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#2e2e2e","theme_color":"#ffed00","display":"minimal-ui","icon":"src/assets/images/colas-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a3aaafc2f61dca56c11ff88452088fe0"},
    },{
      plugin: require('../plugins/publicis-gatsby-plugin-i18n/gatsby-browser.js'),
      options: {"plugins":[],"path":"D:\\BuildsVsTs\\DigiLab_006_004_COLAS\\_work\\5\\s/src/i18n","languages":["fr","en"],"defaultLanguage":"fr","redirect":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
